<template>
    <div class="real-name-auth">
        <div class="header">
            <h2>实名认证</h2>
        </div>
        <div class="content">
            <el-form ref="authForm" :model="authForm" :rules="rules" label-width="0">
                <el-form-item prop="name">
                    <el-input v-model="authForm.name" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item prop="idNumber">
                    <el-input v-model="authForm.idNumber" placeholder="请输入身份证号"></el-input>
                </el-form-item>
                <el-button type="primary" class="submit-button" @click="submitForm()">提交认证</el-button>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            authForm: {
                name: '',
                idNumber: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                idNumber: [
                    { required: true, message: '请输入身份证号', trigger: 'blur' },
                    { pattern: /^[1-9]\d{5}(18|19|20)?\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/, message: '请输入有效的身份证号', trigger: 'blur' }
                ],
            },
        };
    },
    methods: {
        submitForm() {
            var that= this;
            this.$refs.authForm.validate((valid) => {
                if (valid) {
                    let param = {
                        realName: that.authForm.name,
                        idCardNo: that.authForm.idNumber,
                    }
                    that.newApi.userReadNameAuth(param).then((ret)=>{
                        if (ret.isSuccess === 1){
                            that.utils.sus('实名认证成功');
                            that.$store.commit("get_user_info");
                            that.$emit('closeAuth');
                        }
                    })
                } else {
                    return false;
                }
            });
        },
    },
};
</script>

<style scoped>
.real-name-auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    text-align: center;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.close-button {
    position: absolute;
    top: 10px;
    left: 10px;
}

h2 {
    font-size: 18px;
    margin: 0;
}

.content {
    width: 100%;
}

.el-form-item {
    margin-bottom: 20px;
}

.submit-button {
    width: 100%;
    background-color: #409EFF;
    border: none;
    margin-top: 20px;
}
</style>
