<template>
    <div class="join-team">
        <div class="content">
            <el-image :src="roomInfo.cover" class="success-icon" fit="contain"></el-image>
            <h3>已加入</h3>
            <p>你已成功加入「{{roomInfo.name}}」</p>
            <p class="download-info">登录百工宝PC网站<br>体验高效Room协作</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        roomInfo: {
            type: Object,
            default: {
                name:'',
                cover:'https://oss.baigongbao.com/ico/head_ico.png'
            },
        },
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    }
};
</script>

<style scoped>
.join-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    height: 100vh;
    text-align: center;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.close-button {
    position: absolute;
    top: 10px;
    left: 10px;
}

h2 {
    font-size: 18px;
    margin: 0;
}

.content {
    margin-top: 20px;
}

.success-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
}

h3 {
    font-size: 24px;
    color: #333;
    margin: 0;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    color: #666;
    margin: 0;
    margin-bottom: 20px;
}

.experience-button {
    width: 80%;
    background-color: #409EFF;
    border: none;
    margin-bottom: 20px;
}

.download-info {
    font-size: 14px;
    color: #999;
}
</style>
