<template>
    <div class="login-content">
        <h3>选择账号登录</h3>
        <div v-for="(account, index) in accounts" :key="index" @click="selectAccount(index)">
            <div class="account-item" >
                <div v-if="account.uuid == user.uuid" class="current-label">当前登录</div>
                <div class="avatar">
                    <img :src="account.headIco" alt="avatar">
                </div>
                <div class="account-info">
                    <p class="account-name">{{ account.nickName }}</p>
                    <p class="account-phone">{{ account.accoun }}</p>
                </div>
                <div class="account-status">
                    <div>个人账户</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        accounts: {
            type: Array,
            default: [],
        },
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        is_login() {
            return this.$store.state.is_login;
        }
    },
    mounted() {
        console.log(this.accounts)
    },
    methods: {
        selectAccount(index) {
            var that = this;
            // 选择账号登录逻辑
            localStorage.token = that.accounts[index].token;
            localStorage.uuid = that.accounts[index].uuid;
            that.$store.commit("get_user_info", function () {
                that.utils.sus("登录成功");
                that.newApi.updateLoginTime({ uuid: that.accounts[index].uuid });
                that.$emit('closeAccounts', false);
            });
        },
    },
};
</script>

<style scoped>
.login-dialog {
    padding: 20px;
}

.login-content {
    text-align: center;
    padding: 0px;
}

.account-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.account-item:hover {
    background-color: #f5f5f5;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

.avatar img {
    width: 100%;
    height: 100%;
}

.account-info {
    text-align: left;
}

.account-name {
    font-size: 16px;
    font-weight: bold;
}

.account-phone {
    font-size: 14px;
    color: #888;
}

.account-status {
    margin-left: auto; /* 将个人账户靠右对齐 */
    text-align: right;
}

.current-label {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #ff4d4f;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
}

.current-account {
    border: 1px solid #17ADFF;
}

.login-button {
    width: 100%;
    margin-top: 20px;
}
</style>
