<template>
    <div class="login-content">
        <h3>快捷登录</h3>
        <el-form ref="loginForm" :model="loginForm" :rules="rules" label-width="0">
            <div v-if="loginType == 1">
                <el-form-item prop="phone">
                    <el-input v-model="loginForm.phone" :maxlength="11" type="text" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item prop="code">
                    <el-row :gutter="20">
                        <el-col :span="14">
                            <el-input v-model="loginForm.code" :maxlength="6" type="text" placeholder="请输入验证码"></el-input>
                        </el-col>
                        <el-col :span="10">
                            <el-button type="primary" :disabled="countdown > 0" @click="getCode">
                                {{ countdown > 0 ? `${countdown}秒` : '获取验证码' }}
                            </el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
            </div>
            <div v-if="loginType == 2">
                <el-form-item prop="number">
                    <el-input v-model="loginForm.number" type="text" placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item prop="cipher">
                    <el-input v-model="loginForm.cipher" :maxlength="20" type="password" placeholder="请输入密码"></el-input>
                </el-form-item>
            </div>
            <div class="login-options">
                <el-link type="primary" @click="switchLogin">{{ loginMsg }}</el-link>
                <el-link type="primary" @click="regClick">注册账号</el-link>
            </div>
            <el-button type="primary" class="login-button" @click="submitForm('loginForm')">登录</el-button>
            <p class="agreement">登录即同意 <el-link>用户协议</el-link> 和 <el-link>隐私政策</el-link></p>
            <div class="other-login">
                <p>其它方式登录</p>
                <div style="width: 40px;height: 40px;margin: 0 auto;border-radius: 16px;">
                    <img @click="wxLogin" style="width: 100%;height: 100%" src="@/assets/ico/wechat.png">
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    data() {
        return {
            roomId:this.$route.query.state,
            loginType: 1,
            loginMsg: '账号密码登录',
            loginForm: {
                phone: '',
                code: '',
                number: '',
                cipher: '',
            },
            countdown: 0,
            timer: null,
            rules: {
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^1[34578]\d{9}$/, message: '请输入有效的手机号', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' }
                ],
                number: [
                    { required: true, message: '请输入账号', trigger: 'blur' }
                ],
                cipher: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        getCode() {
            var that = this;
            if (this.countdown > 0) return;
            this.$refs.loginForm.validateField('phone', valid => {
                console.log(valid)
                if (valid == '请输入手机号' || valid == '请输入有效的手机号') {
                    // 表单字段验证不通过，清除倒计时并终止计时器
                    this.countdown = 0;
                    clearInterval(this.timer);
                } else {
                    //获取验证码
                    Vue.prototype.openLoading("请稍后...");
                    this.newApi.sendSms({"mobile":that.loginForm.phone,"smsType":3}).then((res)=>{
                        if(res.isSuccess == 1){
                            that.utils.sus(res.data);
                            this.countdown = 60;
                            this.timer = setInterval(() => {
                                if (this.countdown > 0) {
                                    this.countdown--;
                                } else {
                                    clearInterval(this.timer);
                                }
                            }, 1000);
                        }
                    })
                }
            });
        },

        switchLogin() {
            this.clearForm();
            if (this.loginType == 1) {
                this.loginType = 2;
                this.loginMsg = '短信验证码登录';
            } else {
                this.loginType = 1;
                this.loginMsg = '账号密码登录';
            }
        },

        submitForm(formName) {
            let fieldsToValidate = this.loginType === 1 ? ['phone', 'code'] : ['number', 'cipher'];
            this.$refs[formName].validate(valid => {
                if (valid) {
                    // 登录逻辑
                    if(this.loginType == 1){
                        var that = this;
                        let param = {};
                        param.phone = that.loginForm.phone;
                        param.code = that.loginForm.code;
                        param.smsType = 3;
                        localStorage.token = "";
                        Vue.prototype.openLoading("请稍后...");
                        that.newApi.getLoginCode(param).then((res)=>{
                            if(res.isSuccess == 1){
                                this.$emit('userListChanged', res.data);
                            }
                        })
                    }else if(this.loginType == 2){
                        var that = this;
                        let param = {};
                        param.accoun = that.loginForm.number;
                        param.pwd = that.loginForm.cipher;
                        localStorage.token = "";
                        Vue.prototype.openLoading("请稍后...");
                        that.newApi.getLoginAccount(param).then((res)=>{
                            if(res.isSuccess == 1){
                                this.$emit('userListChanged', res.data);
                            }
                        })
                    }
                } else {
                    console.log('表单验证失败');
                    return false;
                }
            }, fieldsToValidate);
        },
        regClick() {
            this.$router.push({ name: 'reg' });
        },
        clearForm() {
            this.$refs.loginForm.resetFields();
        },

        //微信登录
        wxLogin(){
            var that = this;
            if (this.isWeChat()) {
                that.msg = "当前客户端为微信";
                that.newApi.getMLoginCodeUrl({roomId:that.roomId}).then((res) => {
                    if(res.isSuccess == 1){
                        //重定向
                        window.location.href = res.data;
                    }
                })
            }  else {
                that.utils.msg("请用微信客户端扫码");
            }
        },

        //判断客户端
        isWeChat() {
            var ua = window.navigator.userAgent.toLowerCase();
            return /MicroMessenger/i.test(ua);
        },
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },


};
</script>

<style scoped>
.login-dialog {
    padding: 20px;
}

.login-content {
    text-align: center;
}

.login-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.login-button {
    width: 100%;
    margin-top: 20px;
}

.agreement {
    margin: 20px 0;
    text-align: center;
    font-size: 12px;
    color: #999;
}

.other-login {
    text-align: center;
    margin-top: 20px;
}

.wechat-button {
    margin-top: 10px;
}
</style>
